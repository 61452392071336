"use client"

import { useState } from "react"
import Image from "next/image"
import { Locale } from "@/i18n/i18n-config"
import {
  PageBlocksMountainTop,
  ProjectsConnectionQuery,
} from "@/tina/__generated__/types"
import Marquee from "react-fast-marquee"
import { tinaField } from "tinacms/dist/react"

import LazyVideo from "../static/LazyVideo"
import Button from "../ui/Button"

interface MountainTopProps {
  block: PageBlocksMountainTop
  locale: Locale
  projects: ProjectsConnectionQuery
}

export default function MountainTop({
  block,
  locale,
  projects,
}: MountainTopProps) {

  const featuredProjects = projects.projectsConnection.edges?.filter(
    (element) => element?.node?.isFeatured
  )

  const [featuredIndex, setFeaturedIndex] = useState(0)

  return (
    <div className="relative mt-[120px] flex flex-col 2xl:items-center gap-36 lg:mt-0 lg:gap-[300px]">
      <section className="px-[5vw] 2xl:px-0 2xl:w-full 2xl:max-w-[1500px] lg:px-[10vw] lg:pt-[200px]">
        <div className="flex flex-col text-[20px] lg:text-[23px]">
          <div className="flex items-center gap-1.5 text-center sm:text-left">
            <p className="">
              adlerlagune{" "}
              <img
                src="/images/100p.webp"
                alt="adlerlagune logo"
                className="relative inline h-[35px] w-[35px] -translate-y-1 "
              />{" "}
              <span
                data-tina-field={tinaField(block, "firstSentence")}
                className="test-test inline"
              >
                {block.firstSentence}
              </span>
            </p>
          </div>
          <div className="mt-12 flex flex-wrap gap-2 md:mt-4 w-full justify-center sm:w-auto sm:justify-start">
            <span
              className=" whitespace-nowrap"
              data-tina-field={tinaField(block, "secondSentenceSubstringOne")}
            >
              {block.secondSentenceSubstringOne}
            </span>

            <div className="gap-2">
              <div className="w-[50vw] md:w-[150px] ">
                <Marquee
                  className=" rounded-[5px] bg-jet text-white"
                  speed={block.marqueeSpeed || 30}
                >
                  <ul className="flex">
                    {block.marqueeItems?.map((item) => (
                      <li key={item?.label} className="pr-2">
                        {item?.label?.replace(" ", "_")}
                      </li>
                    ))}
                  </ul>
                </Marquee>
              </div>
            </div>
            <span data-tina-field={tinaField(block, "secondSentenceSubstringTwo")}>{block.secondSentenceSubstringTwo}</span>
          </div>
        </div>
        <p
          className="mt-1.5 lg:mt-0 sm:max-w-[75vw] text-[20px] text-center sm:text-left lg:text-[23px]"
          data-tina-field={tinaField(block, "thirdSentence")}
        >
          {block.thirdSentence}
        </p>
      </section>

      <section className="px-[5vw] 2xl:px-0 2xl:w-full 2xl:flex 2xl:flex-col 2xl:items-center pb-20 lg:px-[10vw] lg:pb-[200px]">
        <div className="flex flex-col gap-5 lg:flex-row lg:gap-20 2xl:w-full w-full sm:w-auto items-center text-center sm:text-left sm:items-start 2xl:max-w-[1500px]">
          <div className="">
            <h2
              className="font-var-h2 font-roboto text-[32px] font-bold lg:text-[40px]"
              data-tina-field={tinaField(block, "projectsHeadline")}
            >
              {block.projectsHeadline}
            </h2>
            <p
              className="text-[20px] leading-snug lg:text-[23px]"
              data-tina-field={tinaField(block, "projectsSublineLineOne")}
            >
              {block.projectsSublineLineOne}
            </p>
            <p
              className="text-[20px] leading-snug lg:text-[23px]"
              data-tina-field={tinaField(block, "projectsSublineLineTwo")}
            >
              {block.projectsSublineLineTwo}
            </p>
            <div className="my-8 flex">
              <Button
                isExternal={false}
                link={block.projectsButtonLink || ""}
                buttonText={block.projectsButtonText || "view all projects"}
                tinaFieldData={tinaField(block, "projectsButtonText")}
                locale={locale}
              />
            </div>
            <div className="mb-14 h-20 mt-32 hidden items-center gap-5 lg:flex ">
              <div className="w-[64px] h-[64px] relative">
                {featuredProjects &&
                      featuredProjects[featuredIndex]?.node
                        ?.testimonialImage ? 
                <Image
                  fill
                  //@ts-ignore
                  src={
                    (featuredProjects &&
                      featuredProjects[featuredIndex]?.node
                        ?.testimonialImage) ||
                    ""
                  }
                  
                  className="rounded-full absolute top-0 left-0 w-full h-full bg-cover aspect-square"
                  alt="testimonial image"
                />
                :  null}

              </div>
              <p className=" w-[200px] text-[16px] leading-tight">
                {
                  //@ts-ignore
                  featuredProjects[featuredIndex]?.node?.testimonial?.[
                    locale
                  ] || ""
                }
              </p>
            </div>
          </div>
          <div>
            <div className="relative ">
            <a
                //@ts-ignore
                title={featuredProjects[featuredIndex]?.node?.title[locale] ? `${featuredProjects[featuredIndex]?.node?.title[locale]} - ${featuredProjects[featuredIndex]?.node?.company[locale]}` : "check out project"}
                //@ts-ignore
                href={`/${locale}/projects?project=${featuredProjects[featuredIndex]?.node?.id}` }
                className="project-cutout flex aspect-[8/5] h-full w-[100%] overflow-hidden rounded-2xl  transition-all hover:scale-[1.05] active:scale-[1] xl:h-[510px] xl:w-[800px] xl:rounded-none"
              >
                <div className="top-0 h-full xl:absolute w-full">
                  <LazyVideo
                    //@ts-ignore
                    src={featuredProjects[featuredIndex]?.node?.video}
                  />
                </div>
              </a>
              <div className=" mt-[20px] flex gap-5 justify-center sm:items-start xl:absolute lg:-bottom-10 xl:right-10 xl:mt-0 xl:flex-col">
                <button
                  title={"show this project"}
                  onClick={() =>
                    setFeaturedIndex(
                      featuredIndex === 0 ? 1 : featuredIndex === 1 ? 2 : 0
                    )
                  }
                  className=" h-[50px] w-[75px]  transition-transform hover:scale-110 active:scale-100"
                >
                  <LazyVideo
                    //@ts-ignore
                    src={
                      featuredProjects &&
                      featuredProjects[
                        featuredIndex === 0 ? 1 : featuredIndex === 1 ? 2 : 0
                      ]?.node?.video
                    }
                  />
                </button>

                <button
                  title="show this project"
                  onClick={() =>
                    setFeaturedIndex(
                      featuredIndex === 0 ? 2 : featuredIndex === 1 ? 0 : 1
                    )
                  }
                  className=" h-[50px] w-[75px]  transition-transform hover:scale-110 active:scale-100"
                >
                  <LazyVideo
                    //@ts-ignore

                    src={
                      featuredProjects &&
                      featuredProjects[
                        featuredIndex === 0 ? 2 : featuredIndex === 1 ? 0 : 1
                      ]?.node?.video
                    }
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <svg
        className="absolute right-0 top-36 -z-10 hidden md:block lg:top-0 lg:h-[150%]"
        viewBox="0 0 1120 1893"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1682 1893C1566.47 1314.84 1370.5 239 1182 141.5C1135 112.5 1181 137.096 1055 125.5C1006 118.74 992 1.00007 874.5 1C807.887 0.999963 657 28.5 625.5 125.5C443.055 520.273 373.959 1438.86 0 1633.32L1682 1893Z"
          fill="#6C6B6B"
        />
        <path
          d="M907 1863C907 1587 1196.45 2148.5 874 79.0003L860.5 0.999839C911 0.999806 943.813 -0.37989 999.5 73.5008C1018.72 99.0016 1033.5 121.374 1050.5 124.5C1064.2 127.019 1076.6 127.285 1086.5 128C1108 129.554 1120 129 1120 129L1120.46 1730L1121.5 1863H907Z"
          fill="#5C5B5B"
        />
      </svg>
      <svg
        className="absolute right-0 top-36 -z-10 block md:hidden"
        viewBox="0 0 380 1411"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M341.24 94.7237C292.358 485.289 129.11 1191.76 0.321167 1399.63L381.034 1410.46V0.0546875C363.04 18.9749 346.96 49.0246 341.24 94.7237Z"
          fill="#6C6B6B"
        />
      </svg>
    </div>
  )
}
